import { LazyComponent } from '@components/LazyComponent';
import RouterService from '@services/RouterService';
import { Route } from 'react-router-dom';

function LazyPage() {
  return <LazyComponent factory={() => import('./CardStatements')} />;
}

export const ROUTE_CardStatements = RouterService.create(
  `${RouterService.BASE_PATH}/cards`,
);

export const RouteComponent_CardStatements_Index = (
  <Route index element={<LazyPage />} />
);

export const RouteComponent_CardStatements = (
  <Route path={ROUTE_CardStatements.buildPath({})} element={<LazyPage />} />
);
