import { lazy, Suspense } from 'react';
import { TransactionDrawerProps } from '@pages/statement/components/TransactionDrawer/types';

const DrawerLazy = lazy(
  () => import('../../pages/statement/components/TransactionDrawer/TransactionDrawer'),
);

export function TransactionDrawer(props: TransactionDrawerProps) {
  return (
    <Suspense fallback={<div />}>
      <DrawerLazy {...props} />
    </Suspense>
  );
}
