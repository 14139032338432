import { useNavigate as useBaseNavigate } from 'react-router-dom';
import {
  InParams,
  InSearchParams,
  InStateParams,
  Route,
  route,
  useTypedParams,
  useTypedSearchParams,
  useTypedState,
} from 'react-router-typesafe-routes/dom';

export default class RouterService {
  static BASE_PATH = 'expense/statement';
  /** Declares a new route
   * @example
   *
   * ```
   * const route = RouterService.create(
   *   '/corporateCard/path/:pathParam',
   *    {
   *      params: {
   *        pathParam: zod(z.string()).defined()
   *      },
   *      state: {
   *        stateParam: zod(z.string()).defined()
   *      },
   *    },
   * )
   * ```
   */
  static create = route;

  /** Returns the params from the current route */
  static useParams = useTypedParams;
  /** Returns the search-params from the current route's query string */
  static useSearchParams = useTypedSearchParams;
  /** Returns the state from the current route */
  static useState = useTypedState;

  /** Returns a helper function that navigates with type safety. To go back, use -1 */
  static useNavigate = useNavigate;
}

function useNavigate() {
  const navigate = useBaseNavigate();
  return function <
    TPath extends string,
    TPathTypes,
    TSearchTypes,
    THash extends string[],
    TStateTypes,
  >(input: NavigateInput<TPath, TPathTypes, TSearchTypes, THash, TStateTypes> | -1) {
    if (input === -1) {
      navigate(-1);
      return;
    }
    navigate(input.route.buildPath(input.params, input.searchParams), {
      state: input.state,
    });
  };
}

type NavigateInput<
  TPath extends string,
  TPathTypes,
  TSearchTypes,
  THash extends string[],
  TStateTypes,
> = {
  route: Route<TPath, TPathTypes, TSearchTypes, THash, TStateTypes>;
  params: InParams<TPath, TPathTypes>;
  searchParams: InSearchParams<TSearchTypes>;
  state: InStateParams<TSearchTypes>;
};
