import { LazyComponent } from '@components/LazyComponent';
import RouterService from '@services/RouterService';
import { Route } from 'react-router-dom';

import {
  RouteComponent_CardStatements,
  RouteComponent_CardStatements_Index,
} from './CardStatements';
import { RouteComponent_FailedMovements } from './FailedMovements';
import { RouteComponent_WalletStatements } from './WalletStatements/index';

function LazyPage() {
  return <LazyComponent factory={() => import('./Statement')} />;
}

export const ROUTE_Statement = RouterService.create(`${RouterService.BASE_PATH}`);

export const RouteComponent_Statement = (
  <Route path={ROUTE_Statement.path} element={<LazyPage />}>
    {RouteComponent_CardStatements_Index}
    {RouteComponent_CardStatements}
    {RouteComponent_WalletStatements}
    {RouteComponent_FailedMovements}
  </Route>
);
