import '../src/i18n';

import NiceModal from '@ebay/nice-modal-react';
import { ThemeProvider } from '@flash-tecnologia/hros-web-ui-v2';
import { TrpcProvider } from '@services/corporateCard';

import AppRouter from './routes';

export default function Root() {
  return (
    <ThemeProvider>
      <TrpcProvider>
        <NiceModal.Provider>
          <AppRouter />
        </NiceModal.Provider>
      </TrpcProvider>
    </ThemeProvider>
  );
}
