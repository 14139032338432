import React, { useState } from 'react';
import { AppRouter } from '@bff';
import { getAccessToken } from '@flash-tecnologia/hros-web-utility';
import AuthService from '@services/AuthService';
import EnvService from '@services/EnvService';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import superjson from 'superjson';

export type RouterInputs = inferRouterInputs<AppRouter>;
export type RouterOutputs = inferRouterOutputs<AppRouter>;

const url = EnvService.API_URL;

export const trpc = createTRPCReact<AppRouter>();

type Props = {
  children: React.ReactNode;
};

export function TrpcProvider(props: Props) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
      }),
  );
  const company = AuthService.useCompany();

  const trpcClient = trpc.createClient({
    transformer: superjson,
    links: [
      httpBatchLink({
        url,
        async headers() {
          const accessToken = await getAccessToken();
          return {
            authorization: accessToken,
            'x-flash-auth': `Bearer ${accessToken}`,
            'company-id': company?.id,
          };
        },
      }),
    ],
  });
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>
    </trpc.Provider>
  );
}
