import { LazyComponent } from '@components/LazyComponent';
import RouterService from '@services/RouterService';
import { Route } from 'react-router-dom';

function LazyPage() {
  return <LazyComponent factory={() => import('./WalletStatements')} />;
}

export const ROUTE_WalletStatements = RouterService.create(
  `${RouterService.BASE_PATH}/wallet`,
);

export const RouteComponent_WalletStatements = (
  <Route path={ROUTE_WalletStatements.buildPath({})} element={<LazyPage />} />
);
