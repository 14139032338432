import { RouteComponent_Statement } from '@pages/statement';
import { BrowserRouter, Routes } from 'react-router-dom';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>{RouteComponent_Statement}</Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
